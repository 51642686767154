import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './components/app/app.component';
import { EmptyContainerComponent } from './components/empty-container/empty-container.component';

// shared modules
// modules are imported in app-core, not app-core-routing!

// shared components
import { LoginComponent } from 'local-fwk-login';
// import { RegisterComponent, ForgotPassComponent, PasswordComponent } from '../../../projects/local-fwk-register/src/public_api';
// import {
//   UserEditorComponent,
//   RoleEditorComponent,
//   UserAddComponent,
//   UserIdentityComponent,
//   RolePermissionsListComponent,
//   RolePermissionsComponent,
//   UserRolesComponent,
//   UsersRoleComponent,
//   EmailEditorComponent,
//   EmailAddComponent
// } from '../../../projects/local-fwk-aaa-editors/src/public_api';
// import { BrowserContainerComponent, BrowserModalEmptyComponent } from '../../../projects/local-fwk-browser/src/public_api';
// import {
//   AppComponent,
//   ContainerComponent,
//   NavigatorComponent,
//   NotificationModalComponent,
//   PageNotFoundComponent
// } from '../../../projects/local-fwk-containers/src/public_api';
// import { EditorContainerComponent } from '../../../projects/local-fwk-editor/src/public_api';
// import { LookupTestComponent } from '../fwk-tests-section';
// import { TestAuthorizationGuard } from '../fwk-tests-section/guards/test-authorization.guard';

// guards
// guards are imported in app-core, not app-core-routing!

// services
// services are imported in app-core, not app-core-routing!

const appRoutes: Routes = [
  // { path: '', redirectTo: 'login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  // { path: 'register', component: RegisterComponent },
  // { path: 'forgot-password', component: ForgotPassComponent },
  // { path: 'new-password/:id', component: PasswordComponent },

  // { path: '', redirectTo: 'app', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'app',
    component: AppComponent,
    children: [
      { path: '', component: EmptyContainerComponent },
      {
        path: '**',
        component: EmptyContainerComponent,
        // children: [
        //   { path: '', component: EmptyContainerComponent },
        //   {
        //     path: '**',
        //     component: EmptyContainerComponent,
        //     children: [
        //       { path: '', component: EmptyContainerComponent },
        //       {
        //         path: '**',
        //         component: EmptyContainerComponent,
        //         children: [
        //           { path: '', component: EmptyContainerComponent },
        //           { path: '**', component: EmptyContainerComponent },
        //         ],
        //       },
        //     ],
        //   },
        // ],
      },
    ],
  },
  // { path: 'role', component: RoleEditorComponent },
  // { path: '**', component: PageNotFoundComponent },
  // { path: '**', redirectTo: 'login' },

  // {
  //   path: 'google',
  //   component: AppComponent,
  //   resolve: { url: 'externalUrlRedirectResolver' },
  //   data: { externalUrl: 'http://www.google.com' }
  // },
  // {
  //   path: 'facebook',
  //   component: AppComponent,
  //   resolve: { url: 'externalUrlRedirectResolver' },
  //   data: { externalUrl: 'http://www.facebook.com' }
  // },
  // {
  //   path: 'yahoo',
  //   component: AppComponent,
  //   resolve: { url: 'externalUrlRedirectResolver' },
  //   data: { externalUrl: 'http://www.yahoo.com' }
  // }
];

// tslint:disable-next-line:max-line-length
// rezolva deployul pe nginx https://stackoverflow.com/questions/46612526/how-to-configure-server-side-rendering-for-lazy-loaded-routes-using-angular-univ
export const AppRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' });

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: false, relativeLinkResolution: 'legacy' } // <-- debugging purposes only/
 // <-- debugging purposes only/
    ),
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: '',
      // useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      //   window.location.href = (route.data as any).externalUrl;
      // }
    },
  ],
})
export class CoreRoutingModule {}
