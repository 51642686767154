import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_BASE_HREF, PlatformLocation, registerLocaleData } from '@angular/common';
import { environment } from '../../environments/environment';
import { HttpClientModule } from '@angular/common/http';

// components
import { AppComponent } from './components/app/app.component';
import { MenuComponent } from './components/menu/menu.component';
import { StartContainerComponent } from './components/start-container/start-container.component';

// shared modules
import { LocalFwkCommonModule } from 'local-fwk-common';
import { InactivityTimerComponent } from './components/inactivity-timer/inactivity-timer.component';
// import { LocalFwkBrowserModule } from '../../../projects/local-fwk-browser/src/public_api';
// import { LocalFwkLookupModule } from '../../../projects/local-fwk-lookup/src/public_api';
// import { LocalFwkLoginModule } from '../../../projects/local-fwk-login/src/public_api';
// import { LocalFwkRegisterModule } from '../../../projects/local-fwk-register/src/public_api';
// import { LocalFwkAAAEditorsModule } from '../../../projects/local-fwk-aaa-editors/src/public_api';
// import { LocalFwkContainersModule } from '../../../projects/local-fwk-containers/src/public_api';
// import { LocalFwkEditorModule, EditorDatashareService } from '../../../projects/local-fwk-editor/src/public_api';
import { CoreRoutingModule } from './app-core-routing.module';

// global pipes
// import { DisplayFormatPipe } from 'local-fwk-common';
import localeRo from '@angular/common/locales/ro';

// services
import {
  BentoAuthenticationService,
  BentoAuthorizationService,
  BentoDomRootService,
  BentoGlobalMessagingService,
  BentoHttpWrapperService,
  BentoSecurityConfigService,
  BentoTestEnvironmentCheckService,
  BentoUrlReplaceService,
  BentoUserService,
  LoginRegisterDataSharingService,
} from 'local-fwk-services';
import { WebComponentRouteSharingService } from './services/web-component-route-sharing.service';
// import { AuthService as ExternalProviderAuthenticationService } from 'angular2-social-auth';
// guards
// import { FwkBrowserAuthorizationGuard } from '../../../projects/local-fwk-browser/src/public_api';
// other modules
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { EmptyContainerComponent } from './components/empty-container/empty-container.component';
import { LocalFwkLoginModule } from 'local-fwk-login';
import { WebComponentLoadingService } from './services/web-component-loading.service';
// import { ScriptLoadService } from './services/script-load.service';
// import { ModalModule } from 'ngx-bootstrap';

registerLocaleData(localeRo);

@NgModule({
  imports: [
    // ModalModule.forRoot(),
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,

    CoreRoutingModule,

    LocalFwkCommonModule,
    // LocalFwkBrowserModule,
    // LocalFwkLookupModule,
    LocalFwkLoginModule,
    // LocalFwkRegisterModule,
    // LocalFwkAAAEditorsModule,
    // LocalFwkContainersModule,
    // LocalFwkEditorModule,

    // FwkTestsSectionModule
  ],
  declarations: [
    StartContainerComponent,
    EmptyContainerComponent,
    InactivityTimerComponent,
    AppComponent,
    MenuComponent,
    // ,
    // GenericValidator
  ],
  providers: [
    {
      provide: 'ENVIRONMENT',
      useValue: environment,
    },
    {
      provide: 'DOM_ROOT',
      useClass: BentoDomRootService,
    },
    {
      provide: 'ORIGIN_URL',
      useValue: location.origin,
    },
    {
      provide: 'AUTH_SERVICE',
      useClass: BentoAuthenticationService, // BentoAuthenticationService
    },
    {
      provide: 'AUTHORIZATION_SERVICE',
      useClass: BentoAuthorizationService, // BentoAuthorizationService
    },
    {
      provide: 'HTTP_SERVICE',
      useClass: BentoHttpWrapperService,
    },
    {
      provide: 'USER_SERVICE',
      useClass: BentoUserService, // BentoUserService
    },
    // {
    //   provide: 'BROWSER_GUARD',
    //   useClass: FwkBrowserAuthorizationGuard
    // },
    // {
    //   provide: 'DISPLAY_FORMAT_SERVICE',
    //   useClass: BentoDisplayFormatService
    // },
    {
      provide: 'LOGIN_REGISTER_SHARE_SERVICE',
      useClass: LoginRegisterDataSharingService,
    },
    {
      provide: 'URL_SERVICE',
      useClass: BentoUrlReplaceService,
    },
    {
      provide: 'TEST_ENVIRONMENT_CHECK_SERVICE',
      useClass: BentoTestEnvironmentCheckService,
    },
    {
      provide: 'GLOBAL_MESSAGING_SERVICE',
      useClass: BentoGlobalMessagingService,
    },
    // {
    //   provide: 'EDITOR_DATASHARE_SERVICE',
    //   useClass: EditorDatashareService
    // },
    // {
    //   provide: 'HISTORY_SECTION_LOGGER',
    //   useClass: HistoryLoggerService
    // },
    // {
    //   provide: 'HISTORY_SECTION_PHOTOS',
    //   useClass: HistoryPhotoService
    // },
    // {
    //   provide: 'USER_PASS_VALIDATOR',
    //   useClass: BentoUserPasswordValidatorService
    // },
    {
      provide: 'SECURITY_CONFIG',
      useClass: BentoSecurityConfigService,
    },
    {
      provide: LOCALE_ID,
      useValue: 'ro-RO',
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    WebComponentRouteSharingService,
    WebComponentLoadingService,
    // ScriptLoadService
    // ExternalProviderAuthenticationService
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [StartContainerComponent],
})
export class CoreModule {
  // constructor(router: Router, title: Title) {
  //   router.events.subscribe(event => {
  //     // fires on every URL change
  //     title.setTitle(router.name);
  //   });
  // }
}

// jenkins push test
