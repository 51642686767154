import { Component, OnDestroy, OnInit, EventEmitter, HostListener } from '@angular/core';
import { Subject, timer, Subscription, Observable, interval } from 'rxjs';
import { takeUntil, take, throttleTime } from 'rxjs/operators';
//import { IAuthenticationService } from 'local-fwk-common/src/public_api';
import { Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
//import { IFwkEnvironment } from 'local-fwk-common/src/public_api';
//import { IAutoLogoutEnvironment } from 'local-fwk-common/src/public_api';
import { HttpClient } from '@angular/common/http';
import { IAuthenticationService, IGlobalMessagingService, ISecurityConfigService, SecurityConfig, StringHelper } from 'local-fwk-common';
//import { InactivityTimer } from '../../models/inactivityTimer.model';

@Component({
  selector: 'fwk-inactivity-timer',
  templateUrl: `./inactivity-timer.component.html`,
  styles: [],
})
export class InactivityTimerComponent implements OnInit, OnDestroy {
  public displayedTime: string = '';

  private throttleSubscription: Subscription;
  private timerSubscription: Subscription;
  private throttleEmitter: EventEmitter<void> = new EventEmitter();
  private timerStarted: boolean = false;
  private expiredTimeAt: number;
  private sendWarningMessageAt: number;
  public showedWarningMessage: boolean = false;
  private timeTick: number = 0;
  public timeBeforeLogoutText: string = '';
  private decreaseTimer: number = 0;

  public securityConfig: SecurityConfig;
  private securityConfigLoadedSubscription: Subscription;
  private authLoadedSubscription: Subscription;
  private authIsAuthenticatedSubscription: Subscription;

  constructor(
    @Inject('AUTH_SERVICE') private authService: IAuthenticationService,
    @Inject('GLOBAL_MESSAGING_SERVICE') private globalMessagingService: IGlobalMessagingService,
    @Inject('SECURITY_CONFIG') private securityConfigService: ISecurityConfigService,
    private httpService: HttpClient,
    private router: Router
  ) {}

  public ngOnInit() {
    this.authLoadedSubscription = this.authService.loadedChange.subscribe((authenticationServiceLoaded) => {
      this.loadInactivityTimer();
    });

    // wait for auth service to authenticate
    this.authIsAuthenticatedSubscription = this.authService.isAuthenticatedChange.subscribe((isAuthenticated) => {
      this.loadInactivityTimer();
    });

    this.securityConfigLoadedSubscription = this.securityConfigService.loadedChange.subscribe((loadedResult) => {
      this.loadInactivityTimer();
    });

    this.loadInactivityTimer();
  }

  public ngOnDestroy() {
    this.authLoadedSubscription?.unsubscribe();
    this.authIsAuthenticatedSubscription?.unsubscribe();
    this.securityConfigLoadedSubscription?.unsubscribe();

    this.throttleSubscription?.unsubscribe();
    this.timerSubscription?.unsubscribe();
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  public resetEventsHook() {
    this.throttleEmitter.emit();
  }

  private loadInactivityTimer() {
    if (!this.authService.loaded) {
      return;
    }
    if (!this.authService.isAuthenticated) {
      return;
    }
    if (!this.securityConfigService.loaded) {
      return;
    }

    this.securityConfig = this.securityConfigService.getConfiguration();
    if (this.securityConfig.inactivityTimer != null) {
      if (this.securityConfig.inactivityTimer.enable == true) {
        const disableTimeIntervalStart = this.securityConfig.inactivityTimer.disableTimeIntervalStart;
        const disableTimeIntervalEnd = this.securityConfig.inactivityTimer.disableTimeIntervalEnd;

        if (this.isValidInterval(disableTimeIntervalStart, disableTimeIntervalEnd)) {
          if (!this.isDisabledInInterval(disableTimeIntervalStart, disableTimeIntervalEnd)) {
            if (
              this.securityConfig.inactivityTimer.logoutWarningMsgSeconds < this.securityConfig.inactivityTimer.logoutAfterSeconds &&
              this.securityConfig.inactivityTimer.logoutAfterSeconds > 0
            ) {
              this.startTimer();
            } else {
              this.globalMessagingService.setErrorMessage('Error on InactivityTimer configuration.', 'global', 2);
              console.log('Error on InactivityTimer configuration.');
            }
          }
        } else {
          this.globalMessagingService.setErrorMessage('Error on InactivityTimer configuration.', 'global', 2);
          console.log('Error on InactivityTimer configuration.');
        }
      }
    }
  }

  private startTimer() {
    if (!this.timerStarted) {
      this.timerStarted = true;
      this.timeTick = 0;
      this.decreaseTimer = 0;
      this.expiredTimeAt = this.securityConfig.inactivityTimer.logoutAfterSeconds;
      this.sendWarningMessageAt =
        this.securityConfig.inactivityTimer.logoutAfterSeconds - this.securityConfig.inactivityTimer.logoutWarningMsgSeconds;
      this.timerSubscription = interval(1000).subscribe(() => {
        this.calculateTimerTick();
      });

      this.throttleSubscription = this.throttleEmitter.pipe(throttleTime(1000)).subscribe(() => {
        this.resetTimer();
      });

      this.calculateTimerTick();
    }
  }

  private calculateTimerTick() {
    if (this.timerStarted) {
      this.timeTick += 1;
      if (this.timeTick > this.sendWarningMessageAt) {
        const timeBeforeLogout = this.securityConfig.inactivityTimer.logoutWarningMsgSeconds - this.decreaseTimer;
        this.timeBeforeLogoutText = this.securityConfig.inactivityTimer.logoutWarningMsg.replace('{0}', timeBeforeLogout.toString());

        this.decreaseTimer += 1;
        if (this.showedWarningMessage == false) {
          this.showedWarningMessage = true;
        }
      }
      if (this.timeTick > this.expiredTimeAt) {
        this.callLogout();
      }
    }
  }

  private resetTimer() {
    if (this.timerStarted) {
      this.showedWarningMessage = false;
      this.timeTick = 0;
      this.decreaseTimer = 0;
      this.calculateTimerTick();
    }
  }

  private callLogout() {
    // TODO: this logout will not redirect back to original page when logged back in
    this.authService.logout().subscribe((logout_result) => {
      this.router.navigate(['']);
    });
  }

  // private isValidInterval(intervalText: string): boolean {
  //   var isValid = true;

  //   if (StringHelper.isNullOrWhiteSpace(intervalText)) {
  //     return false;
  //   }

  //   var regex = /([01]?[0-9]|2[0-3])-([01]?[0-9]|2[0-3])?/;
  //   if (!regex.test(intervalText)) {
  //     return false;
  //   }

  //   var intervalTextSplitted = intervalText.split('-');
  //   var startTimeInterval = Number(intervalTextSplitted[0]);
  //   var endTimeInterval = Number(intervalTextSplitted[1]);
  //   if (startTimeInterval > endTimeInterval) {
  //     return false;
  //   }

  //   return isValid;
  // }

  // private isDisabledInInterval(intervalText: string): boolean {
  //   var isDisabled = false;
  //   var startTimeInterval = Number(intervalText.split('-')[0]);
  //   var endTimeInterval = Number(intervalText.split('-')[1]);

  //   if (startTimeInterval == 0 && endTimeInterval == 0) {
  //     return false;
  //   }

  //   var dateNow = new Date();
  //   if (dateNow.getHours() >= startTimeInterval && dateNow.getHours() < endTimeInterval) {
  //     return true;
  //   }

  //   return isDisabled;
  // }
  
  private isValidInterval(intervalStart: number, intervalEnd: number): boolean {
    var isValid = true;

    intervalStart = intervalStart == null ? 0 : intervalStart;
    intervalEnd = intervalEnd == null ? 0 : intervalEnd;

    if (!(0 <= intervalStart && intervalStart <= 24)) isValid = false;
    if (!(0 <= intervalEnd && intervalEnd <= 24)) isValid = false;
    if (!(intervalStart < intervalEnd)) isValid = false;

    return isValid;
  }

  private isDisabledInInterval(intervalStart: number, intervalEnd: number): boolean {
    var isDisabled = false;
    
    intervalStart = intervalStart == null ? 0 : intervalStart;
    intervalEnd = intervalEnd == null ? 0 : intervalEnd;

    var dateNow = new Date();
    if (intervalStart <= dateNow.getHours() && dateNow.getHours() < intervalEnd) {
      isDisabled = true;
    }

    return isDisabled;
  }
}
