import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { throwError as observableThrowError } from 'rxjs';
import {
  IAuthenticationService,
  IAuthorizationService,
  IFwkDOMRoot,
  IFwkEnvironment,
  IGlobalMessagingService,
  IHttpService,
  ITestEnvironmentCheckService,
  IUrlReplaceService,
  IUserService,
  StringHelper,
} from 'local-fwk-common';
import { WebComponentItem, WebComponents } from '../../models/web-components.model';
import { WebComponentRouteSharingService } from '../../services/web-component-route-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { WebComponentLoadingService } from '../../services/web-component-loading.service';
import { InactivityTimerComponent } from '../inactivity-timer/inactivity-timer.component';

// import { ScriptLoadService } from '../../services/script-load.service';

@Component({
  selector: 'app-shell',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  public userName: string = null;
  public isDebug: boolean = this.environment.debugMode;
  public debugPermissions: boolean = this.environment.debugPermissions;

  public webComponentsConfiguration: WebComponents;

  public urlServiceLoaded: boolean = false;
  public userServiceLoaded: boolean = false;
  public authorizationServiceLoaded: boolean = false;
  public authenticationServiceLoaded: boolean = false;
  public domRootServiceLoaded: boolean = false;
  public loaded: boolean = false;

  public isTestEnvironment: boolean = false;

  public footerItems: string[] = [];

  @ViewChild('footer') footer: ElementRef;
  @ViewChild('openModal') modalOpen: ElementRef;
  @ViewChild('contentWrapper') contentWrapper: ElementRef;
  @ViewChild('inactivity') inactivityTimer: InactivityTimerComponent;

  public asideClass = 'main-sidebar';
  private innerWidth: number;

  @ViewChild('rootPlaceholder') rootPlaceHolderElement: ElementRef;

  constructor(
    @Inject('ENVIRONMENT') public environment: IFwkEnvironment,
    @Inject('DOM_ROOT') private domRoot: IFwkDOMRoot,
    @Inject('URL_SERVICE') private urlReplaceService: IUrlReplaceService,
    @Inject('AUTH_SERVICE') public authService: IAuthenticationService,
    @Inject('HTTP_SERVICE') public httpService: IHttpService,
    @Inject('USER_SERVICE') public userService: IUserService,
    @Inject('AUTHORIZATION_SERVICE')
    public authorizationService: IAuthorizationService,
    @Inject('TEST_ENVIRONMENT_CHECK_SERVICE') public testEnvironmentService: ITestEnvironmentCheckService,
    @Inject('GLOBAL_MESSAGING_SERVICE')
    public globalMessaging: IGlobalMessagingService,
    private renderer: Renderer2,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private elementRef: ElementRef,
    private webComponentRouteSharingService: WebComponentRouteSharingService,
    private webComponentLoadingService: WebComponentLoadingService // private scriptLoadService: ScriptLoadService
  ) {}

  ngOnInit(): void {
    this.urlReplaceService.initService();

    this.urlReplaceService.loadedChange.subscribe((value) => {
      this.urlServiceLoaded = value;
      this.loadInternal();
    });
    this.urlServiceLoaded = this.urlReplaceService.loaded;

    // requires urlReplaceService
    this.authService.loadedChange.subscribe((value) => {
      this.authenticationServiceLoaded = value;

      if (this.authenticationServiceLoaded) {
        this.authService.refreshAuthentication();
      }

      this.loadInternal();
    });
    this.authenticationServiceLoaded = this.authService.loaded;

    // requires urlReplaceService, authService, authService.isAuthenticated
    this.userService.loadedChange.subscribe((value) => {
      this.userServiceLoaded = value;
      this.loadInternal();
    });
    this.userServiceLoaded = this.userService.loaded;

    // requires urlReplaceService, authService, authService.isAuthenticated
    this.authorizationService.loadedChange.subscribe((value) => {
      this.authorizationServiceLoaded = value;
      this.loadInternal();
    });
    this.authorizationServiceLoaded = this.authorizationService.loaded;

    if (this.authenticationServiceLoaded) {
      this.authService.refreshAuthentication();
    }

    // requires nothing
    this.domRoot.isLoadedChange.subscribe((value) => {
      this.domRootServiceLoaded = value;
    });
    this.domRootServiceLoaded = this.domRoot.isLoaded;

    // this.testEnvironmentService.loadConfiguration();
    // this.testEnvironmentService.isLoadedSub.subscribe((loaded) => (this.testEnvironmentServiceLoaded = loaded));
    this.testEnvironmentService.isThisTestEnvironmentSub.subscribe((value) => (this.isTestEnvironment = value));

    this.loadInternal();
    this.initFooter();
  }

  private loadInternal() {
    if (this.urlServiceLoaded && this.userServiceLoaded && this.authorizationServiceLoaded && this.authenticationServiceLoaded) {
      if (this.loaded) {
        return;
      }

      this.loadAuthorisedWebComponents();
      this.initAside();

      setTimeout(() => {
        this.innerWidth = window.innerWidth;
        this.changeFooterHeightOnResize();
      }, 0);

      this.loaded = true;
    }
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event) {
    this.innerWidth = window.innerWidth;
    this.changeFooterHeightOnResize();
  }

  public ngAfterViewInit() {
    this.innerWidth = window.innerWidth;
    this.changeFooterHeightOnResize();

    if (this.debugPermissions) {
      this.modalOpen.nativeElement.click();
    }

    this.domRoot.assignRoot(this.rootPlaceHolderElement);
  }

  private loadAuthorisedWebComponents() {
    const path = './config/web-components.json';

    return new Promise((resolve, reject) => {
      this.httpService
        .get(path)
        .pipe(
          catchError((error: any): any => {
            console.log('Configuration file "' + path + '" could not be read');
            resolve(true);
            return observableThrowError(error.json().error || 'Server error');
          })
        )
        .subscribe((config_result: HttpResponse<any>) => {
          const temp_res = <any>config_result.body;
          this.webComponentsConfiguration = temp_res;

          // if (window['bryntum']) {
          //   window['bryntum'] = {};
          // }

          // if (window['System']) {
          //   window['System'] = {};
          // }

          for (let index = 0; index < this.webComponentsConfiguration.authorised.length; index++) {
            // console.log('testest 123testest123 testest', this.webComponentsConfiguration.authorised[index]);
            this.loadComponent(this.webComponentsConfiguration.authorised[index]);
          }

          resolve(true);
        });
    });
  }

  private loadComponent(configItem: WebComponentItem): void {
    if (configItem.enabled === false) {
      return;
    }

    let cssElements: HTMLLinkElement[] = [];
    const scriptElements: HTMLScriptElement[] = [];
    let webcElement: HTMLElement = null;

    const contentElement: HTMLElement = document.getElementById('content');
    // const bodyElement = document.getElementsByTagName('body')[0];
    const divId = 'webc_' + configItem.name;
    const elemExists = Array.from(contentElement.children).find((x) => x.id === divId);
    if (elemExists) {
      return;
    }

    const divWrapperElement = document.createElement('div');
    divWrapperElement.id = divId;
    divWrapperElement.className = 'webc-wrapper-layout';

    // this.scriptLoadService.loadScripts(configItem);
    this.webComponentLoadingService.registerWebc(configItem);

    if (configItem.cssPaths != null) {
      for (let index = 0; index < configItem.cssPaths.length; index++) {
        const element = configItem.cssPaths[index];
        const link = document.createElement('link');
        link.type = 'text/css';
        link.rel = 'stylesheet';
        link.href = element;
        link.onerror = () => console.error(`error loading ${configItem.path}`);

        cssElements.push(link);
      }
    }

    if (configItem.element) {
      webcElement = document.createElement(configItem.element);

      webcElement.addEventListener('routeSnapshotChange', (newRouteEvent) => {
        if (this.isDebug) {
          console.log(
            '[fwk-shell.AppComponent.loadComponent.addEventListener (routeSnapshotChange) -> eventListener] event ',
            newRouteEvent
          );
        }

        this.webComponentRouteSharingService.handleRouteChange((newRouteEvent as any).detail, null);
      });

      webcElement.addEventListener('componentCreatedChange', (newRouteEvent) => {
        if (this.isDebug) {
          console.log(
            '[fwk-shell.AppComponent.loadComponent.addEventListener (componentCreatedChange) -> eventListener] event ',
            newRouteEvent,
            webcElement.shadowRoot
          );
        }

        if (cssElements != null) {
          // add all CSSs elements
          cssElements.forEach((element) => {
            webcElement.shadowRoot.appendChild(element);
          });
          // remove all CSSs link elements from list, by making the list null, so GC can clear it
          cssElements = null;
        }
      });

      webcElement.addEventListener('refreshTokensRequiredChange', (newRTEvent) => {
        if (this.isDebug) {
          console.log(
            '[fwk-shell.AppComponent.loadComponent.addEventListener (refreshTokensRequiredChange) [TRT] -> eventListener] event ',
            newRTEvent,
            webcElement.shadowRoot
          );
        }

        this.authService.refreshTokens().subscribe(
          (success) => {
            webcElement.setAttribute('refresh-tokens-completed', new Date().toISOString());
          },
          (error) => {
            webcElement.setAttribute('refresh-tokens-completed', 'FAILED');
          }
        );
      });

      this.authService.isAuthenticatedChange.subscribe((isAuthenticated) => {
        if (isAuthenticated) {
          webcElement.setAttribute('login-completed', new Date().toISOString());
        } else {
          webcElement.setAttribute('logout-completed', new Date().toISOString());
        }
      });

      const urlRoute = decodeURI(this.router.url);
      const currentRouteData = this.webComponentRouteSharingService.getCurrentRouteData(urlRoute);

      if (this.isDebug) {
        console.log('[fwk-shell.AppComponent.loadComponent] routes', urlRoute, currentRouteData);
      }

      webcElement.setAttribute('use-route-snapshot-object', 'true');
      webcElement.setAttribute('route-snapshot', currentRouteData.RouteSnapshot);
      webcElement.setAttribute('route-snapshot-object', JSON.stringify(currentRouteData));
      if (this.authService.isAuthenticated) {
        webcElement.setAttribute('login-completed', new Date().toISOString());
      } else {
        webcElement.setAttribute('logout-completed', new Date().toISOString());
      }

      this.webComponentRouteSharingService.registerClient(webcElement);
    }

    divWrapperElement.appendChild(webcElement);

    contentElement.appendChild(divWrapperElement);

    if (this.isDebug) {
      setTimeout(() => {
        console.log('[FwkShell.loadComponent] - ngDoBootstrap test', customElements.get(configItem.element));
      }, 5000);
    }
  }

  // private handleMessage(msg): void {
  //   console.debug('shell received message: ', msg.detail);
  // }

  public permissionsModalOpener(func: any) {
    if (this.debugPermissions && func != null) {
      func();
    }
  }

  public getDivMargin() {
    if (
      this.globalMessaging.getSuccessMessage('global') !== '' ||
      this.globalMessaging.getErrorMessage('global') !== '' ||
      this.globalMessaging.getWarningMessage('global') !== ''
    ) {
      return '41px';
    }
  }

  private changeFooterHeightOnResize() {
    try {
      if (this.footer != null && this.contentWrapper != null) {
        const windowHeight = window.innerHeight;
        const footerHeight = this.footer.nativeElement.offsetHeight;
        const minHeight = windowHeight - footerHeight;
        this.renderer.setStyle(this.contentWrapper.nativeElement, 'min-height', minHeight + 'px'.toString());
      }
    } catch (err) {
      console.error(err);
    }
  }

  private initAside() {
    // moved from demo.js
    this.renderer.removeClass(document.body, 'skin-blue');
    this.renderer.removeClass(document.body, 'sidebar-mini');
    this.renderer.removeClass(document.body, 'fixed');
    this.renderer.removeClass(document.body, 'sidebar-mini-expand-feature');
    this.renderer.removeClass(document.body, 'control-sidebar-open');
    this.renderer.removeClass(document.body, 'sidebar-collapse');
    this.renderer.removeClass(document.body, 'sidebar-open');

    this.renderer.addClass(document.body, 'skin-blue');
    this.renderer.addClass(document.body, 'sidebar-mini');
    this.renderer.addClass(document.body, 'fixed');
    this.renderer.addClass(document.body, 'sidebar-mini-expand-feature');
  }

  public toggleAside() {
    if (this.innerWidth > 768) {
      this.renderer.removeClass(document.body, 'sidebar-open');

      if (this.asideClass.startsWith('sidebar-collapse')) {
        this.asideClass = 'main-sidebar';
        this.renderer.removeClass(document.body, 'sidebar-collapse');
      } else {
        this.asideClass = 'sidebar-collapse main-sidebar';
        this.renderer.addClass(document.body, 'sidebar-collapse');
      }
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');

      if (this.asideClass.startsWith('sidebar-collapse')) {
        this.asideClass = 'main-sidebar';
        this.renderer.removeClass(document.body, 'sidebar-open');
      } else {
        this.asideClass = 'sidebar-collapse main-sidebar';
        this.renderer.addClass(document.body, 'sidebar-open');
      }
    }
  }

  public initFooter(): void {
    let footerConfiguration = this.environment.bodyFooterMessage;

    if (StringHelper.isNullOrWhiteSpace(footerConfiguration)) {
      footerConfiguration = 'Acest site a fost creat cu <heartIcon> de catre <bento>.';
    }

    const finalItems: string[] = [];
    const footerConfigurationItems = footerConfiguration.split('<');
    footerConfigurationItems.forEach((value, index, list) => {
      if (index !== 0) {
        list[index] = '<' + value;
      }

      const innerFooterConfigurationItems = list[index].split('>');
      innerFooterConfigurationItems.forEach((innerValue, innerIndex, innerList) => {
        if (innerIndex !== innerFooterConfigurationItems.length - 1) {
          innerList[innerIndex] = innerValue + '>';
        }

        finalItems.push(innerList[innerIndex]);
      });
    });

    this.footerItems = finalItems;
  }
}
