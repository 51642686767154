import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, NavigationStart, Params, Router } from '@angular/router';
import { IFwkEnvironment, MASRouteSnapshotSyncData, StringHelper } from 'local-fwk-common';

@Injectable()
export class WebComponentRouteSharingService {
  private clients: HTMLElement[] = [];
  // private routeSnapshotHere: string = '';
  private currentRouteData = {} as MASRouteSnapshotSyncData;

  public isDebug: boolean = this.environment.debugMode;

  constructor(@Inject('ENVIRONMENT') public environment: IFwkEnvironment, private activatedRoute: ActivatedRoute, private router: Router) {
    this.currentRouteData.RouteSnapshot = '';
  }


  paramsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) { // each 'entry' is a [key, value] tupple
      result[key] = value;
    }
    return result;
  }

  public getCurrentRouteData(completeUrlRoute: string): MASRouteSnapshotSyncData {
    let baseUrl: string;
    let urlParts: string[];
    let params: Params = null;
    let queryParamsUrl: string;

    urlParts = completeUrlRoute.split('?');
    const routeData = {} as MASRouteSnapshotSyncData;
    routeData.RouteSnapshot = '';

    if (Array.isArray(urlParts) && urlParts !== null && urlParts.length > 1) {
      baseUrl = urlParts[0];
      queryParamsUrl = urlParts[1].trim();
      if (!StringHelper.isNullOrWhiteSpace(queryParamsUrl)) {
        const urlParams = new URLSearchParams(queryParamsUrl);
        const entries = Array.from(urlParams);
        params = this.paramsToObject(entries);
      }
      routeData.RouteSnapshot = baseUrl;

      if (params === null) {
        routeData.NavigationExtras = null;
      } else {
        routeData.NavigationExtras = {} as NavigationExtras;
        routeData.NavigationExtras.queryParams = params;
      }

    } else {
      routeData.RouteSnapshot = completeUrlRoute;
      routeData.NavigationExtras = null;
    }

    return routeData;
  }

  public handleRouteChange(urlRoute: string, event: NavigationStart): void {
    if (this.isDebug) {
      console.log('[fwk-shell.WebComponentRouteSharingService.handleRouteChange] route received: ', urlRoute);
    }

    urlRoute = decodeURI(urlRoute);
    const routeData = this.getCurrentRouteData(urlRoute);

    if (this.currentRouteData.RouteSnapshot !== routeData.RouteSnapshot ||
      JSON.stringify(this.currentRouteData.NavigationExtras) !== JSON.stringify(routeData.NavigationExtras)) {

      this.currentRouteData = JSON.parse(JSON.stringify(routeData));

      if (this.isDebug) {
        console.log('[fwk-shell.WebComponentRouteSharingService.handleRouteChange] route changed: ', this.currentRouteData);
      }

      if (this.router.url !== urlRoute && event == null) {
        if (this.isDebug) {
          console.log('[fwk-shell.WebComponentRouteSharingService.handleRouteChange] navigation changed: ', this.currentRouteData);
        }
        if (this.currentRouteData.NavigationExtras !== null) {
          this.router.navigate([this.currentRouteData.RouteSnapshot], this.currentRouteData.NavigationExtras);
        } else {
          this.router.navigate([this.currentRouteData.RouteSnapshot]);
        }
        // setTimeout(() => {
        //   this.router.navigate([urlRoute]);
        // }, 0);
      }

      for (const client of this.clients) {
        if (client != null) {
          if (this.isDebug) {
            console.log('[fwk-shell.WebComponentRouteSharingService.handleRouteChange] route sending: (+ use snapshot) ', this.currentRouteData);
          }
          client.setAttribute('use-route-snapshot-object', 'true');
          client.setAttribute('route-snapshot', this.currentRouteData.RouteSnapshot);
          client.setAttribute('route-snapshot-object', JSON.stringify(this.currentRouteData));
        }
      }
    }
  }


  public registerClient(client: HTMLElement) {
    if (this.isDebug) {
      console.log('[fwk-shell.WebComponentRouteSharingService.registerClient] client registered: ', client);
    }
    this.clients.push(client);
  }
}
