import { ElementRef, EventEmitter, Injectable } from '@angular/core';
import { WebComponentItem } from '../models/web-components.model';

@Injectable()
export class WebComponentLoadingService {
  private webComponentItems: HTMLScriptElement[] = [];
  private webComponentDiv: HTMLElement;
  // public newRegister: EventEmitter<string> = new EventEmitter;

  public loaded: boolean = false;
  public loadedChange: EventEmitter<boolean> = new EventEmitter;

  constructor() {


  }

  registerWebcArea(element: ElementRef) {
    this.webComponentDiv = element.nativeElement;

    if (this.webComponentDiv != null) {
      this.loaded = true;
      this.loadedChange.emit(this.loaded);
    } else {
      this.loaded = false;
      this.loadedChange.emit(this.loaded);
    }
  }

  registerWebc(configItem: WebComponentItem): HTMLScriptElement[] {
    const scriptElements: HTMLScriptElement[] = [];

    if (configItem.path != null) {
      const script = document.createElement('script');
      script.src = configItem.path;
      script.type = 'text/javascript';
      script.defer = true;
      script.onerror = () => console.error(`error loading ${configItem.path}`);

      scriptElements.push(script);
    } else if (configItem.jsPaths != null) {
      for (let index = 0; index < configItem.jsPaths.length; index++) {

        const element = configItem.jsPaths[index];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = element;
        script.defer = true;
        script.onerror = () => console.error(`error loading ${configItem.path}`);

        scriptElements.push(script);
      }
    }

    scriptElements.forEach((script) => {
      let scriptExists = false;

      for (let index = 0; index < this.webComponentItems.length; index++) {
        const existingScript = this.webComponentItems[index];

        if (existingScript.src === script.src) {
          scriptExists = true;
          break;
        }
      }

      if (!scriptExists) {
        this.webComponentItems.push(script);
        this.webComponentDiv.appendChild(script);
      }
    });

    return scriptElements;
  }

}
