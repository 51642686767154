<div
  *ngIf="this.showedWarningMessage === true"
  class="inactivity-timer-container"
>
  <table>
    <tr>
      <td>
        <div class="inactivity-timer-message">
          <p>{{ timeBeforeLogoutText }}</p>
        </div>
      </td>
    </tr>
  </table>
</div>
