<section class="sidebar-wrapper">
  <div class="top-sidebar">
    <div
      [class]="
        'logo' +
        (isTestEnvironment ? ' test-environment-style-override-logo' : '')
      "
    >
      <a
        [routerLink]="[
          this.menuConfig?.homeLinkUrl == null
            ? '/app'
            : this.menuConfig.homeLinkUrl
        ]"
      >
        <!-- mini logo for sidebar mini 50x50 pixels -->
        <span class="logo-mini">
          <img src="shell/images/logo-white-small.png" alt="" />
        </span>
        <!-- logo for regular state and mobile devices -->
        <span class="logo-lg">
          <img src="shell/images/logo-white.png" />
        </span>
      </a>
      <a
        href="javascript:void(0);"
        class="sidebar-toggle"
        role="button"
        (click)="toggleAside.emit()"
      >
        <!-- data-toggle="push-menu" role="button" -->
        <!-- toggleAside() -->
        <div class="toggle-icon">
          <i class="fa fa-arrow-circle-right"></i>
        </div>
        <span class="sr-only">Toggle navigation</span>
      </a>
    </div>

    <!-- sidebar: style can be found in sidebar.less -->
    <section class="sidebar" *ngIf="menuConfig?.type === 'folder'">
      <!-- Sidebar user panel -->
      <div class="user-panel">
        <div class="image float-left">
          <img
            src="shell/images/user2.jpg"
            class="rounded-circle"
            alt="User Image"
          />
        </div>

        <div class="info float-left">
          <p>
            <!-- {{getTenantDescription()}} -->
            Bento
          </p>
        </div>

        <!-- search form -->
        <!-- <form action="#" method="get" class="sidebar-form" style="visibility: hidden">
            <div class="input-group">
              <input name="q" type="text" class="form-control" placeholder="Search...">
              <span class="input-group-btn">
                <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                  <i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </form> -->
        <!-- /.search form -->
      </div>

      <!-- sidebar menu: : style can be found in sidebar.less -->
      <ul class="sidebar-menu" data-widget="tree">
        <ng-container
          *ngIf="currentMenuPath != null && currentMenuPath.length > 0"
        >
          <li class="sidebar-menu-back">
            <a href="javascript:void(0);" (click)="menuUp()">
              <span class="pull-left-container">
                <i class="fa fa-angle-left pull-left"></i>
              </span>
              <div style="margin-left: 10px"></div>
              <i
                *ngIf="!!currentMenuPath[currentMenuPath.length - 1].icon"
                [class]="currentMenuPath[currentMenuPath.length - 1].icon"
              ></i>
              <span>{{
                currentMenuPath[currentMenuPath.length - 1].name
              }}</span>
            </a>
          </li>
        </ng-container>

        <li *ngFor="let menu of currentMenuPathElements">
          <a
            *ngIf="menu.subMenus == null"
            [routerLink]="[resolveLink(menu.link)]"
            [routerLinkActive]="['active']"
          >
            <i *ngIf="!!menu.icon" [class]="menu.icon"></i>
            <span>{{ menu.name }}</span>
            <!-- <span class="pull-right-container">
                <i class="fa fa-angle-right pull-right"></i>
              </span> -->
          </a>
          <a
            *ngIf="menu.subMenus != null"
            href="javascript:void(0);"
            (click)="menuClicked(menu)"
          >
            <i *ngIf="!!menu.icon" [class]="menu.icon"></i>
            <span>{{ menu.name }}</span>
            <span class="pull-right-container">
              <i class="fa fa-angle-right pull-right"></i>
            </span>
          </a>
        </li>
      </ul>
    </section>

    <!-- <div class="row m-0" style="padding-top: 0px">
        <div class="col-lg-2 col-md-3 col-1 p-0 collapse show in width" id="sidebar" style="background-color: #333">
          <div class="list-group panel">
      -->

    <ng-template #menuLevel let-menuList="menuList" let-menuLvl="menuLvl">
      <div *ngFor="let menuItem of menuList">
        <a
          *ngIf="
            menuItem.subMenus == null &&
            menuItem.external === true &&
            menuItem.openNewTab !== true
          "
          href="{{ menuItem.link }}"
          [class]="
            'list-group-item level-' +
            menuLvl +
            (isTestEnvironment
              ? ' test-environment-style-override-list-group-item'
              : '')
          "
          data-parent="#sidebar"
        >
          <div class="menu-item-data">
            <span *ngIf="!!menuItem.icon" [class]="menuItem.icon"></span>
            <span
              [class]="
                'link-name' +
                (isTestEnvironment
                  ? ' test-environment-style-override-link-name'
                  : '')
              "
              >{{ menuItem.name }}</span
            >
          </div>
        </a>

        <a
          *ngIf="
            menuItem.subMenus == null &&
            menuItem.external !== true &&
            menuItem.openNewTab !== true
          "
          [routerLink]="[menuItem.link]"
          [routerLinkActive]="['active']"
          [class]="
            'list-group-item level-' +
            menuLvl +
            (isTestEnvironment
              ? ' test-environment-style-override-list-group-item'
              : '')
          "
          data-parent="#sidebar"
        >
          <div class="menu-item-data">
            <span *ngIf="!!menuItem.icon" [class]="menuItem.icon"></span>
            <span
              [class]="
                'link-name' +
                (isTestEnvironment
                  ? ' test-environment-style-override-link-name'
                  : '')
              "
              >{{ menuItem.name }}</span
            >
            <span
              *ngIf="menuItem.notificationsUrl !== null"
              [class]="
                menuItem.internalNotificationsIsHighlighted
                  ? menuItem.notificationsIconHighlighted
                  : menuItem.notificationsIcon
              "
            >
            </span>
          </div>
        </a>

        <a
          *ngIf="menuItem.subMenus == null && menuItem.openNewTab === true"
          (click)="resolveMenuNewWebsite(menuItem.external, menuItem.link)"
          href="javascript:void(0);"
          [class]="
            'list-group-item level-' +
            menuLvl +
            (isTestEnvironment
              ? ' test-environment-style-override-list-group-item'
              : '')
          "
          data-parent="#sidebar"
        >
          <div class="menu-item-data">
            <span *ngIf="!!menuItem.icon" [class]="menuItem.icon"></span>
            <span
              [class]="
                'link-name' +
                (isTestEnvironment
                  ? ' test-environment-style-override-link-name'
                  : '')
              "
              >{{ menuItem.name }}</span
            >
          </div>
        </a>

        <a
          *ngIf="menuItem.subMenus !== null"
          (click)="checkMenuState(menuLvl)"
          [href]="'#menu' + menuItem.id"
          [class]="
            'list-group-item level-' +
            menuLvl +
            ' collapsed' +
            (isTestEnvironment
              ? ' test-environment-style-override-list-group-item'
              : '')
          "
          data-toggle="collapse"
          data-parent="#sidebar"
          aria-expanded="false"
        >
          <div class="menu-item-data">
            <span *ngIf="!!menuItem.icon" [class]="menuItem.icon"></span>
            <span
              [class]="
                'link-name' +
                (isTestEnvironment
                  ? ' test-environment-style-override-link-name'
                  : '')
              "
              >{{ menuItem.name }}</span
            >
          </div>
          <i
            style="float: right"
            class="fa fa-angle-down"
            aria-hidden="true"
          ></i>
        </a>

        <div
          [class]="
            'collapse' +
            (isTestEnvironment
              ? ' test-environment-style-override-menu-before'
              : '')
          "
          [id]="'menu' + menuItem.id"
          [ngClass]="{ 'sub-men': menuItem.id.toString().length >= 2 }"
        >
          <!-- next level-->
          <ng-container
            *ngTemplateOutlet="
              menuLevel;
              context: { menuList: menuItem.subMenus, menuLvl: menuLvl + 1 }
            "
          ></ng-container>
        </div>
      </div>
    </ng-template>

    <section
      *ngIf="menuConfig?.type === 'collapse'"
      class="menu-collapse"
      [style.height.px]="this.menuHeight"
    >
      <ng-container
        [class]="
          'list-group-item' +
          (isTestEnvironment
            ? ' test-environment-style-override-list-group-item'
            : '')
        "
        *ngTemplateOutlet="
          menuLevel;
          context: { menuList: menuConfig?.menuList, menuLvl: 1 }
        "
      ></ng-container>
    </section>
  </div>
  <div
    [class]="
      'bottom-sidebar' +
      (isTestEnvironment
        ? ' test-environment-style-override-bottom-sidebar'
        : '')
    "
  >
    <a
      class="usr"
      [routerLink]="[
        this.menuConfig?.settingsLinkUrl == null
          ? '/app'
          : this.menuConfig.settingsLinkUrl
      ]"
      routerActiveLink="Yes"
    >
      <img
        src="shell/images/user2.jpg"
        class="user-image rounded-circle"
        alt="User Image"
      />
      <div class="usr-info">
        <small
          [class]="
            'user-name' +
            (isTestEnvironment
              ? ' test-environment-style-override-user-name'
              : '')
          "
          *ngIf="userService.getUserName()"
          title="{{ userService.getUserName() }}"
          >{{ userService.getUserName() }}</small
        >
        <small
          class="user-mail"
          [class]="
            'user-mail' +
            (isTestEnvironment
              ? ' test-environment-style-override-user-mail'
              : '')
          "
          *ngIf="userService.getUserMail()"
          title="{{ userService.getUserMail() }}"
          >{{ userService.getUserMail() }}</small
        >
      </div>
    </a>

    <div class="actions">
      <a href="javascript:void(0);" class="sign-out" (click)="logout()">
        <i
          [class]="
            'fa fa-sign-out' +
            (isTestEnvironment
              ? ' test-environment-style-override-fa-sign-out'
              : '')
          "
          aria-hidden="true"
        ></i>
      </a>
    </div>
  </div>
</section>
