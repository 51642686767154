// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IShellEnvironment } from '../app/app-core/models/fwk-shell-environment.model';
import { baseEnvironment } from './base_environment';

export const environment: IShellEnvironment = {
  production: false,
  debugMode: true,
  debugPermissions: false,
  appTitle: baseEnvironment.appTitle,
  appVersion: baseEnvironment.version,

  bodyFooterMessage: baseEnvironment.bodyFooterMessage,
  bodyFooterShowVersion: baseEnvironment.bodyFooterShowVersion
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
