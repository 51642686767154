<div #rootPlaceholder></div>

<ng-container
  *ngIf="
    !(
      urlServiceLoaded &&
      userServiceLoaded &&
      authorizationServiceLoaded &&
      authenticationServiceLoaded &&
      domRootServiceLoaded
    ) && isDebug
  "
>
  UrlService: {{ urlServiceLoaded }} <br />
  UserService: {{ userServiceLoaded }} <br />
  AuthorizationService: {{ authorizationServiceLoaded }} <br />
  AuthenticationService: {{ authenticationServiceLoaded }}
</ng-container>

<!-- TODO replace with something fancy (a loading gif of some sorts) -->
<ng-container
  *ngIf="
    !(
      urlServiceLoaded &&
      userServiceLoaded &&
      authorizationServiceLoaded &&
      authenticationServiceLoaded &&
      domRootServiceLoaded
    )
  "
>
  Loading app...
</ng-container>

<ng-container
  *ngIf="
    urlServiceLoaded &&
    userServiceLoaded &&
    authorizationServiceLoaded &&
    authenticationServiceLoaded &&
    domRootServiceLoaded
  "
>
  <body class="skin-blue sidebar-mini fixed sidebar-mini-expand-feature">
    <div class="wrapper">
      <!-- Content Wrapper. Contains page content -->
      <div class="content-wrapper" #contentWrapper>
        <div id="content" class="content">
          <fwk-inactivity-timer #inactivity></fwk-inactivity-timer>
          <!-- web components go here -->

          <!-- dummy router outlet for empty route -->
          <router-outlet></router-outlet>
        </div>
        <!-- BEGIN: Error Handling Zone: GLOBAL -->
        <div [style.margin-bottom]="getDivMargin()">
          <div style="position: fixed; z-index: 100; width: 100%">
            <div
              *ngIf="globalMessaging.getErrorMessage('global') != ''"
              class="alert alert-danger"
              [innerHTML]="globalMessaging.getErrorMessage('global')"
            ></div>
            <div
              *ngIf="globalMessaging.getWarningMessage('global') != ''"
              class="alert alert-warning"
              [innerHTML]="globalMessaging.getWarningMessage('global')"
            ></div>
            <div
              *ngIf="globalMessaging.getSuccessMessage('global') != ''"
              class="alert alert-success"
              [innerHTML]="globalMessaging.getSuccessMessage('global')"
            ></div>
          </div>
        </div>
        <!-- END: Error Handling Zone: GLOBAL -->

        <!-- {{ 'Shell Route: ' + router.url }} -->
      </div>
      <!-- /.content-wrapper -->
      <!-- <header class="main-header">
        <app-shell-navbar></app-shell-navbar>
      </header> -->

      <!-- Left side column. contains the logo and sidebar -->
      <aside
        class="main-sidebar"
        [class]="
          'main-sidebar' +
          menuLvl +
          (isTestEnvironment
            ? ' test-environment-style-override-main-sidebar'
            : '')
        "
      >
        <app-shell-menu (toggleAside)="toggleAside()"></app-shell-menu>
      </aside>

      <footer class="main-footer" #footer>
        <div class="fwk-app-developer">
          <ng-container *ngFor="let footerItem of footerItems">
            <ng-container *ngIf="footerItem === '\<bento>'"
              ><a target="_blank" href="https://www.bento.ro/"
                >Bento</a
              ></ng-container
            >
            <ng-container *ngIf="footerItem === '\<heartIcon>'"
              ><i class="fa fa-heart" aria-hidden="true"></i
            ></ng-container>
            <ng-container
              *ngIf="footerItem !== '\<bento>' && footerItem !== '\<heartIcon>'"
              >{{ footerItem }}</ng-container
            >
          </ng-container>
          <!-- Acest site a fost creat cu <i class="fa fa-heart" aria-hidden="true"></i> de catre
          <a target="_blank" href="https://www.bento.ro/">Bento</a> -->
        </div>
        <div
          *ngIf="environment.bodyFooterShowVersion !== false"
          class="fwk-app-version"
        >
          Versiunea {{ environment.appVersion }}
        </div>
      </footer>
    </div>
  </body>
</ng-container>

<!-- modal with roles and permissions for debug -->
<!-- <button id="openModal" #openModal [hidden]="true" (click)="permissionsModalOpener(notificationModal.openModal())"></button>
<fwk-notification-modal #notificationModal></fwk-notification-modal> -->
