import { forkJoin, Observable, throwError as observableThrowError, Subscription, interval } from 'rxjs';
import { AfterViewInit, Component, EventEmitter, HostListener, Inject, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import {
  IAuthenticationService,
  IAuthorizationService,
  IFwkDOMRoot,
  IFwkEnvironment,
  IHttpService,
  ITestEnvironmentCheckService,
  IUrlReplaceService,
  IUserService,
  StringHelper,
} from 'local-fwk-common';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-shell-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  public loaded: boolean;
  public message: string;
  public menuConfig: MenuAsideConfig;
  public currentMenuPath: MenuAsideItem[] = [];
  public currentMenuPathElements: MenuAsideItem[] = [];
  private notificationsSubscriptionList: Subscription[] = [];

  public menuHeight: number = 0;
  @Output() toggleAside: EventEmitter<any> = new EventEmitter();

  public isTestEnvironment: boolean = false;

  public isDebug: boolean = this.environment.debugMode;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.calcSidebarHeight();
  }

  constructor(
    @Inject('ENVIRONMENT') public environment: IFwkEnvironment,
    private router: Router,
    private route: ActivatedRoute,
    @Inject('HTTP_SERVICE') private http: IHttpService,
    @Inject('AUTH_SERVICE') public authService: IAuthenticationService,
    @Inject('HTTP_SERVICE') public httpService: IHttpService,
    @Inject('USER_SERVICE') public userService: IUserService,
    @Inject('AUTHORIZATION_SERVICE')
    private authorizationService: IAuthorizationService,
    @Inject('URL_SERVICE') private urlReplaceService: IUrlReplaceService,
    @Inject('DOM_ROOT') private domRoot: IFwkDOMRoot,
    @Inject('TEST_ENVIRONMENT_CHECK_SERVICE') public testEnvironmentService: ITestEnvironmentCheckService
  ) {}

  ngOnInit() {
    this.loadMenu().then((loadResult) => {
      // perform initial navigation as per homeLinkUrl definition
      if (
        loadResult === true &&
        this.menuConfig != null &&
        this.router.url === '/app' &&
        this.menuConfig.homeLinkUrl != null &&
        this.router.url !== this.menuConfig.homeLinkUrl
      ) {
        this.router.navigate([this.menuConfig.homeLinkUrl]);
      }
    });

    this.testEnvironmentService.isThisTestEnvironmentSub.subscribe((value) => (this.isTestEnvironment = value));
  }

  ngAfterViewInit(): void {
    this.calcSidebarHeight();
  }

  //TODO: unsubscribe meniu recursiv
  ngOnDestroy(): void {
    for (let index = 0; index < this.notificationsSubscriptionList.length; index++) {
      this.notificationsSubscriptionList[index]?.unsubscribe();
    }
    this.unsubscribeMenusRecursive(this.menuConfig.menuList);
  }

  public unsubscribeMenusRecursive(menuList: MenuAsideItem[]): void {
    for (let index = 0; index < menuList.length; index++) {
      const element = menuList[index];

      element.internalNotificationsSubscription?.unsubscribe();
      if (element.subMenus !== null && element.subMenus.length > 0) {
        this.unsubscribeMenusRecursive(element.subMenus);
      }
    }
  }

  public loadMenu() {
    const path = './config/menu-aside.json';

    return new Promise((resolve, reject) => {
      this.http.get(path).subscribe(
        (config_result: HttpResponse<any>) => {
          const temp_res = <any>config_result.body;

          const tempMenuConfig: MenuAsideConfig = new MenuAsideConfig();

          if (temp_res.type) {
            tempMenuConfig.type = temp_res.type;
          }
          if (temp_res.homeLinkUrl) {
            tempMenuConfig.homeLinkUrl = temp_res.homeLinkUrl;
          }
          if (temp_res.settingsLinkUrl) {
            tempMenuConfig.settingsLinkUrl = temp_res.settingsLinkUrl;
          }

          const completeMenuItemList: MenuAsideItem[] = temp_res.menuList ? <MenuAsideItem[]>temp_res.menuList : <MenuAsideItem[]>temp_res;

          if (completeMenuItemList != null && completeMenuItemList.length > 0) {
            this.autoAssignIdsPlusUntagRecursive(completeMenuItemList, 1);
            const permissionsList: string[] = this.getPermissionsListRecursive(completeMenuItemList, []);

            // we create a list of isThisAllowedTo(permission) to invoke/wait simultaneously
            const actionsObservableList: Observable<boolean>[] = [];
            for (let index = 0; index < permissionsList.length; index++) {
              actionsObservableList.push(this.authorizationService.isThisAllowedTo(permissionsList[index]));
            }

            forkJoin(actionsObservableList).subscribe((result: boolean[]) => {
              // we create a list of allowedPermissionsm to filter the menu with
              const allowedPermissionsList: string[] = [];
              for (let index = 0; index < result.length; index++) {
                if (result[index] === true) {
                  allowedPermissionsList.push(permissionsList[index]);
                }
              }

              // we validate each menu, submenu, etc (recursively), and hide the ones lacking permissions
              tempMenuConfig.menuList = this.validatePermissionsRecursive(completeMenuItemList, allowedPermissionsList);

              //here we set the notification bell to count how many notifications are in the request from notificationsUrl
              //if is under 0, class is just fa-bell, else set class -> fa-bell .notif-item
              for (let index = 0; index < tempMenuConfig.menuList.length; index++) {
                this.startNotificationEvents(tempMenuConfig.menuList[index]);
              }

              this.menuConfig = tempMenuConfig;
              this.menuUp();
              resolve(true);
            });
          } else {
            // if completeMenuItemList.length = 0
            tempMenuConfig.menuList = [];
            this.menuConfig = tempMenuConfig;
            resolve(true);
          }
        },
        (error: any): any => {
          console.log('Configuration file "' + path + '" could not be read');
          resolve(false);
          return observableThrowError(error.json().error || 'Server error');
        }
      );
    });
  }

  private autoAssignIdsPlusUntagRecursive(currentList: MenuAsideItem[], currentId: number): number {
    for (let index = 0; index < currentList.length; index++) {
      // assign id for current menu entry
      currentList[index].id = currentId;
      currentId++;

      if (!StringHelper.isNullOrWhiteSpace(currentList[index].link)) {
        currentList[index].link = this.urlReplaceService.getUntaggedUrl(currentList[index].link);
      }

      // we add ids to all current menu's sub-menus
      if (currentList[index].subMenus != null && currentList[index].subMenus.length > 0) {
        currentId = this.autoAssignIdsPlusUntagRecursive(currentList[index].subMenus, currentId);
      }
    }

    return currentId;
  }

  private getPermissionsListRecursive(currentList: MenuAsideItem[], permissionList: string[]): string[] {
    for (let index = 0; index < currentList.length; index++) {
      // we add non-empty-string permissions, while checking for duplicates
      if (!StringHelper.isNullOrWhiteSpace(currentList[index].permission)) {
        if (permissionList.indexOf(currentList[index].permission) < 0) {
          permissionList.push(currentList[index].permission);
        }
      }

      // we check all sub-menus for required permissions
      if (currentList[index].subMenus != null && currentList[index].subMenus.length > 0) {
        permissionList = this.getPermissionsListRecursive(currentList[index].subMenus, permissionList);
      }
    }

    return permissionList;
  }

  private validatePermissionsRecursive(currentList: MenuAsideItem[], allowedPermissionList: string[]): MenuAsideItem[] {
    for (let index = 0; index < currentList.length; index++) {
      if (!StringHelper.isNullOrWhiteSpace(currentList[index].permission)) {
        if (allowedPermissionList.indexOf(currentList[index].permission) < 0) {
          currentList.splice(index, 1);
          index--;
          continue;
        }
      }

      if (currentList[index].subMenus != null && currentList[index].subMenus.length > 0) {
        const allowedSubMenus = this.validatePermissionsRecursive(currentList[index].subMenus, allowedPermissionList);

        // if we have a "folder" but all it's items were removed, we remove current menu
        // ("folder" means current item has subMenus and no url)
        if (StringHelper.isNullOrWhiteSpace(currentList[index].link) && allowedSubMenus?.length === 0) {
          currentList.splice(index, 1);
          index--;
        } else {
          currentList[index].subMenus = allowedSubMenus;
        }
      }
    }

    return currentList;
  }

  public checkMenuState(level: number): void {
    const sidebarCollapse = document.getElementsByClassName('sidebar-collapse').length;
    if (level === 1 && sidebarCollapse) {
      this.toggleAside.emit();
    }
  }

  public resolveMenuNewWebsite(external: boolean, link: string) {
    // We are not interested if external is true, or false because we don't use routerLink.
    window.open(link, '_blank').focus();
  }

  public resolveLink(link: string): string {
    return this.urlReplaceService.getUntaggedUrl(link);
  }

  public navigateHomeUrl(): string {
    let homeLinkUrl = this.menuConfig?.homeLinkUrl;
    if (StringHelper.isNullOrWhiteSpace(homeLinkUrl)) {
      homeLinkUrl = '/app';
    }

    return this.resolveLink(homeLinkUrl);
  }

  public navigateSettingsUrl(): string {
    let settingsLinkUrl = this.menuConfig?.settingsLinkUrl;
    if (StringHelper.isNullOrWhiteSpace(settingsLinkUrl)) {
      settingsLinkUrl = '/app';
    }

    return this.resolveLink(settingsLinkUrl);
  }

  public menuUp(): void {
    if (this.isDebug) {
      console.log('[NavigatorComponent.menuUp]', this.currentMenuPath, this.currentMenuPathElements);
    }

    if (this.currentMenuPath.length > 1) {
      this.currentMenuPath.pop();
      this.currentMenuPathElements = this.currentMenuPath[this.currentMenuPath.length - 1].subMenus;
    } else {
      if (this.currentMenuPath.length > 0) {
        this.currentMenuPath.pop();
      }
      this.currentMenuPathElements = this.menuConfig.menuList;
    }
  }

  public logout() {
    const sub = this.authService.logout();

    if (sub !== null) {
      sub.subscribe((logout_result) => {
        this.router.navigate(['']);
      });
    }
  }

  public calcSidebarHeight() {
    const logo = this.domRoot.root.querySelector('.sidebar-wrapper .logo');
    const bottom = this.domRoot.root.querySelector('.sidebar-wrapper .bottom-sidebar');
    const innerHeight = window.innerHeight;

    if (this.isDebug) {
      console.log('[NavigatorComponent.calcSidebarHeight] start', this.menuHeight);
    }

    if (logo && bottom) {
      this.menuHeight = innerHeight - logo.clientHeight - bottom.clientHeight - 20;
    }

    if (this.isDebug) {
      console.log('[NavigatorComponent.calcSidebarHeight] end', this.menuHeight);
    }
  }

  public menuClicked(menuItem: MenuAsideItem): void {
    if (this.isDebug) {
      console.log('[NavigatorComponent.menuItem]', this.currentMenuPath, this.currentMenuPathElements, menuItem);
    }

    if (menuItem.subMenus != null && menuItem.subMenus.length > 0) {
      this.currentMenuPath.push(menuItem);
      this.currentMenuPathElements = menuItem.subMenus;
    } else {
      this.router.navigate([menuItem.link]);
    }
  }

  private startNotificationEvents(menuItem: MenuAsideItem): void {
    if (StringHelper.isNullOrWhiteSpace(menuItem.notificationsUrl)) {
      menuItem.notificationsUrl = null;
      return;
    }

    if (!(menuItem.notificationsFetchDelaySeconds > 0)) {
      menuItem.notificationsFetchDelaySeconds = 5;
    }

    if (StringHelper.isNullOrWhiteSpace(menuItem.notificationsIcon)) {
      menuItem.notificationsIcon = 'fa fa-bell';
    }

    if (StringHelper.isNullOrWhiteSpace(menuItem.notificationsIconHighlighted)) {
      menuItem.notificationsIconHighlighted = 'fa fa-bell fwk-shell-menu-default-notification-highlight';
    }

    menuItem.internalNotificationsIsHighlighted = false;
    menuItem.internalNotificationsSubscription = null;

    // TODO BUG/ISSUE 1: this should start instantly, then delayed. Right now if set for "60", it will wait 60s for first call after every page refresh
    // TODO ISSUE 2: no unsubscribe before subscribing again
    // TODO ISSUE 3: duplicate subscription storage in memory:
    //  - notificationsSubscriptionList for onDestroy
    //  - menuItem.internalNotificationsSubscription for startNotificationEvents
    const intervalSubscription = interval(menuItem.notificationsFetchDelaySeconds * 1000).subscribe(() => {
      return new Promise((resolve, reject) => {
        if (menuItem.internalNotificationsSubscription == null) {
          menuItem.internalNotificationsSubscription = this.httpService
            .get<number>(menuItem.notificationsUrl, 'default-with-token')
            .pipe(
              catchError((error: any): any => {
                console.log('Notification url "' + menuItem.notificationsUrl + '" could not be found');
                resolve(true);
                return observableThrowError(error.json().error || 'Server error');
              })
            )
            .subscribe(
              (config_result: HttpResponse<number>) => {
                const temp_res = config_result.body;
                menuItem.internalNotificationsIsHighlighted = temp_res > 0;
                //const counter = Number(temp_res);
                resolve(true);
              },
              (error: any) => {
                resolve(true);
              },
              () => {
                menuItem.internalNotificationsSubscription = null;
              }
            );
        }
      });
    });
    this.notificationsSubscriptionList.push(intervalSubscription);
  }
}

export class MenuAsideConfig {
  public homeLinkUrl: string;
  public settingsLinkUrl: string;

  public type: string;
  public menuList: MenuAsideItem[];
}

export class MenuAsideItem {
  public id: number;
  public name: string;
  public link: string;
  public external: boolean;
  public openNewTab: boolean;
  public param: string;
  public subMenus: MenuAsideItem[];
  public icon: string;

  public permission: string;

  public notificationsUrl: string;
  public notificationsIcon: string;
  public notificationsIconHighlighted: string;
  public notificationsFetchDelaySeconds: number;
  /** Internal use only */
  public internalNotificationsSubscription: Subscription;
  /** Internal use only */
  public internalNotificationsIsHighlighted: boolean;

  getMenuClass() {
    if (!!this.subMenus && this.subMenus.length > 0) {
      return ' dropdown';
    } else {
      return '';
    }
  }

  constructor(@Optional() id?: number) {
    this.id = id;
  }
}
