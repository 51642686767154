import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { IFwkEnvironment, ITestEnvironmentCheckService, IUrlReplaceService } from 'local-fwk-common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { WebComponentRouteSharingService } from '../../services/web-component-route-sharing.service';
import { WebComponentLoadingService } from '../../services/web-component-loading.service';

@Component({
  selector: 'app-shell-start-container',
  templateUrl: './start-container.component.html',
})
export class StartContainerComponent implements OnInit, AfterViewInit {
  public urlReplaceServiceLoaded: boolean = false;
  public webComponentLoadingServiceLoaded: boolean = false;
  @ViewChild('shellScriptsJS') shellScriptsJS: ElementRef;

  public testEnvironmentServiceLoaded: boolean = false;

  public isDebug: boolean = this.environment.debugMode;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private webComponentRouteSharingService: WebComponentRouteSharingService,
    private webComponentLoadingService: WebComponentLoadingService,
    @Inject('ENVIRONMENT') private environment: IFwkEnvironment,
    @Inject('URL_SERVICE') private urlReplaceService: IUrlReplaceService,
    @Inject('TEST_ENVIRONMENT_CHECK_SERVICE') public testEnvironmentService: ITestEnvironmentCheckService
  ) {}

  ngOnInit(): void {
    this.urlReplaceService.initService();
    this.urlReplaceService.loadedChange.subscribe((value) => {
      this.urlReplaceServiceLoaded = value;
    });
    this.urlReplaceServiceLoaded = this.urlReplaceService.loaded;

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        // console.log(event);
        this.webComponentRouteSharingService.handleRouteChange(event.url, event);
        // this.routeSnapshotChange.emit()
      }
      // NavigationEnd
      // NavigationCancel
      // NavigationError
      // RoutesRecognized
    });

    this.testEnvironmentService.loadConfiguration();
    this.testEnvironmentService.isLoadedSub.subscribe((loaded) => (this.testEnvironmentServiceLoaded = loaded));
  }

  ngAfterViewInit(): void {
    this.webComponentLoadingService.registerWebcArea(this.shellScriptsJS);
    this.webComponentLoadingService.loadedChange.subscribe((value) => {
      if (this.webComponentLoadingServiceLoaded !== value) {
        this.webComponentLoadingServiceLoaded = value;
      }
    });
    this.webComponentLoadingServiceLoaded = this.webComponentLoadingService.loaded;
  }
}
